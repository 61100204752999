<template>
  <base-modal @close="$emit('close')">
    <div class="relative p-10">
      <button class="absolute right-5 top-5" @click="$emit('close')">
        <inline-svg src="/img/icons/close.svg" />
      </button>
      <slot></slot>
    </div>
  </base-modal>
</template>

<script>
  export default {
    name: 'DiscardChangesModal',
  };
</script>

<style lang="scss" scoped></style>
