<template>
  <div class="flex flex-col gap-y-4">
    <div
      class="youtube-video relative cursor-pointer rounded-lg bg-grey-10"
      @mouseenter="$emit('mouseenter')"
      @mouseleave="$emit('mouseleave')"
    >
      <img
        class="inset-0 h-full w-full rounded-lg object-cover"
        :src="video.snippet.thumbnails.high.url"
        alt="Video Thumbnail"
      />
      <div
        class="absolute bottom-2 right-2 rounded-2xl bg-ds-outline-secondary px-4 py-1 text-ds-text-primary"
      >
        {{ formatVideoTime(video) }}
      </div>
      <div
        v-if="isHovering && hoveredItemIndex === currentItemIndex"
        class="overlay absolute inset-0 z-10 grid h-full w-full cursor-default place-items-center rounded-lg"
      >
        <new-button @click="$emit('select-video', video)" type="secondary" size="xs"
          >Select</new-button
        >
      </div>
    </div>
    <div>
      <h3 class="truncate text-ellipsis mb-2 max-w-full text-lg font-bold text-ds-text-primary">
        {{ video.snippet.title }}
      </h3>
      <div class="text-ds-text-secondary">
        <span>{{ formatNumberOfViews(video) }} Views</span> •
        <span>{{ formatTimeAgo(video) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'YoutubeVideo',
    props: {
      video: {
        type: Object,
        required: true,
      },
      isHovering: {
        type: Boolean,
        default: false,
      },
      hoveredItemIndex: {
        type: Number,
        default: null,
      },
      currentItemIndex: {
        type: Number,
        required: true,
      },
    },
    methods: {
      formatNumberOfViews(video) {
        const numOfViews = Number(video.statistics.viewCount);
        return numOfViews.toLocaleString();
      },
      formatTimeAgo(video) {
        const publishedAt = moment(video.snippet.publishedAt);
        const now = moment();
        const duration = moment.duration(now.diff(publishedAt));
        const days = duration.asDays();

        if (days < 1) {
          return 'Today';
        } else if (days < 30) {
          const n = Math.floor(days);
          return `${n} day${n > 1 ? 's' : ''} ago`;
        } else if (days < 365) {
          const n = Math.floor(days / 30);
          return `${n} month${n > 1 ? 's' : ''} ago`;
        } else {
          const n = Math.floor(days / 365);
          return `${n} year${n > 1 ? 's' : ''} ago`;
        }
      },
      formatVideoTime(video) {
        const duration = moment.duration(video.contentDetails.duration);

        const formattedComponents = [duration.hours(), duration.minutes(), duration.seconds()].map(
          (component) => component.toString().padStart(2, '0')
        );

        return formattedComponents.filter((part, index) => part !== '00' || index > 0).join(':');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .youtube-video {
    width: 100%;
    height: 250px;
  }
  .overlay {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
  }
</style>
