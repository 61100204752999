<template>
  <div :class="{ 'mb-6': type === 'mini' }">
    <p
      v-if="type === 'full' && videos.length"
      class="text-lg font-semi-medium text-ds-text-primary"
    >
      Select one of your Youtube videos to transform it to Tweet
    </p>
    <p
      v-else-if="type === 'full' && !videos.length"
      class="text-lg font-semi-medium text-ds-text-primary"
    >
      Your channel has no videos uploaded
    </p>
    <div class="flex items-center gap-x-8" v-if="videos.length">
      <new-button type="custom" class="rounded-full bg-ds-button-secondary-background">
        <inline-svg
          src="/img/icons/new/platforms-arrow-small-unbordered.svg"
          class="h-5 w-5 rotate-90 transform stroke-ds-button-icon"
        />
      </new-button>
      <div
        class="mt-8 grid grid-cols-3 gap-8"
        :class="{ 'grid-rows-3': type === 'full', 'grid-rows-1': type === 'mini' }"
      >
        <youtube-video
          v-for="(video, index) in videos"
          :key="video.id.videoId"
          :video="video"
          :isHovering="isHovering"
          :hoveredItemIndex="hoveredItemIndex"
          :currentItemIndex="index"
          @select-video="$emit('select-video', video)"
          @mouseenter="showOverlay(index)"
          @mouseleave="hideOverlay()"
        />
      </div>
      <new-button type="custom" class="rounded-full bg-ds-button-secondary-background">
        <inline-svg
          src="/img/icons/new/platforms-arrow-small-unbordered.svg"
          class="h-5 w-5 -rotate-90 transform stroke-ds-button-icon"
        />
      </new-button>
    </div>
  </div>
</template>
<script>
  import YoutubeVideo from '@/components/YoutubeToTweet/YoutubeVideo.vue';
  export default {
    name: 'ChooseVideo',
    components: { YoutubeVideo },
    data() {
      return {
        isHovering: false,
        hoveredItemIndex: null,
      };
    },
    props: {
      videos: {
        type: Array,
        required: true,
      },
      type: {
        type: String,
        default: 'full',
        validator(value) {
          return ['full', 'mini'].includes(value);
        },
      },
    },
    methods: {
      showOverlay(index) {
        this.hoveredItemIndex = index;
        this.isHovering = true;
      },
      hideOverlay() {
        this.isHovering = false;
        this.hoveredItemIndex = null;
      },
    },
  };
</script>
